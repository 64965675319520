import generateConstants from 'state-manager/constants/helpers/generate-constants'

// config
export const APPLICATION_CONFIGS_GET = 'APPLICATION_CONFIGS_GET/'

// auth
const SIGN_IN = 'SIGN_IN'
const SIGN_UP = 'SIGN_UP'
const INVITATION_SIGN_UP = 'INVITATION_SIGN_UP'
const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
const RESET_PASSWORD = 'RESET_PASSWORD'
const SIGN_OUT = 'SIGN_OUT'
const VERIFY_EMAIL = 'VERIFY_EMAIL'
const RESEND_EMAIL = 'RESEND_EMAIL'

export const AUTH_ACTIONS = generateConstants({
  SIGN_IN,
  SIGN_UP,
  INVITATION_SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_OUT,
  VERIFY_EMAIL,
  RESEND_EMAIL,
})

// user
const USER_GET_DATA = 'USER_GET_DATA'
const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD'
const USER_UPDATE = 'USER_UPDATE'
const USER_UPLOAD_AVATAR = 'USER_UPLOAD_AVATAR'
const USER_DELETE_AVATAR = 'USER_DELETE_AVATAR'
const USER_UPDATE_2FA = 'USER_UPDATE_2FA'
const USER_UPDATE_TECH_SUPPORT = 'USER_UPDATE_TECH_SUPPORT'
const USER_SKIP_GUIDE = 'USER_SKIP_GUIDE'

export const USER_ACTIONS = generateConstants({
  USER_GET_DATA,
  USER_UPDATE_PASSWORD,
  USER_UPDATE,
  USER_UPLOAD_AVATAR,
  USER_DELETE_AVATAR,
  USER_UPDATE_2FA,
  USER_UPDATE_TECH_SUPPORT,
  USER_SKIP_GUIDE,
})
export const PRODUCT_FRUITS = {
  LOAD_MAIN_DATA: 'LOAD_MAIN_DATA',
  RESET_MAIN_DATA: 'RESET_MAIN_DATA',
}

export const GLOBAL_MODAL = {
  SHOW: 'SHOW_MODAL',
  HIDE: 'HIDE_MODAL',
}

export const CLIENT_MODAL = {
  SHOW: 'SHOW_CLIENT_MODAL',
  HIDE: 'HIDE_CLIENT_MODAL',
}

export const ADD_TEAM_MEMBER_MODAL = {
  SHOW: 'SHOW_ADD_TEAM_MEMBER_MODAL',
  HIDE: 'HIDE_ADD_TEAM_MEMBER_MODAL',
}

export const DEMO_MODAL = {
  SHOW: 'SHOW_DEMO_MODAL',
  HIDE: 'HIDE_DEMO_MODAL',
}

// budgets
const BUDGET_GET_ALL = 'BUDGET_GET_ALL'
const BUDGET_GET_CHART_DATA = 'BUDGET_GET_CHART_DATA'
const BUDGET_GET_SINGLE = 'BUDGET_GET_SINGLE'
const BUDGET_DELETE_SINGLE = 'BUDGET_DELETE_SINGLE'
const BUDGET_ADD = 'BUDGET_ADD'
const BUDGET_UPDATE = 'BUDGET_UPDATE'
const BUDGET_UPDATE_STATUS = 'BUDGET_UPDATE_STATUS'
const BUDGET_EXPORT_TABLE = 'BUDGET_EXPORT_TABLE'
const BUDGET_EXPORT_SINGLE = 'BUDGET_EXPORT_SINGLE'
const MANGE_BUDGET_SET_STEP = 'MANGE_BUDGET_SET_STEP'
const BUDGET_GET_AVAILABLE_AMOUNT = 'BUDGET_GET_AVAILABLE_AMOUNT'
const BUDGET_SET_FORM_VALUES = 'BUDGET_SET_FORM_VALUES'
const BUDGET_CLEAR_FORM_VALUES = 'BUDGET_CLEAR_FORM_VALUES'

export const BUDGET_ACTIONS = generateConstants({
  BUDGET_GET_ALL,
  BUDGET_GET_CHART_DATA,
  BUDGET_GET_SINGLE,
  BUDGET_DELETE_SINGLE,
  BUDGET_ADD,
  BUDGET_UPDATE,
  BUDGET_UPDATE_STATUS,
  BUDGET_EXPORT_TABLE,
  MANGE_BUDGET_SET_STEP,
  BUDGET_GET_AVAILABLE_AMOUNT,
  BUDGET_SET_FORM_VALUES,
  BUDGET_CLEAR_FORM_VALUES,
  BUDGET_EXPORT_SINGLE,
})

// clients
const CLIENT_GET_ALL = 'CLIENT_GET_ALL'
const CLIENT_GET_CHART_DATA = 'CLIENT_GET_CHART_DATA'
const CLIENT_GET_SINGLE = 'CLIENT_GET_SINGLE'
const CLIENT_DELETE_SINGLE = 'CLIENT_DELETE_SINGLE'
const CLIENT_ADD = 'CLIENT_ADD'
const CLIENT_UPDATE = 'CLIENT_UPDATE'
const CLIENT_UPDATE_STATUS = 'CLIENT_UPDATE_STATUS'
const CLIENT_EDIT = 'CLIENT_EDIT'
const CLIENT_EXPORT_TABLE = 'CLIENT_EXPORT_TABLE'

export const CLIENT_ACTIONS = generateConstants({
  CLIENT_GET_ALL,
  CLIENT_GET_CHART_DATA,
  CLIENT_GET_SINGLE,
  CLIENT_DELETE_SINGLE,
  CLIENT_ADD,
  CLIENT_UPDATE,
  CLIENT_UPDATE_STATUS,
  CLIENT_EDIT,
  CLIENT_EXPORT_TABLE,
})

// campaigns
const CAMPAIGN_GET_ALL = 'CAMPAIGN_GET_ALL'
const CAMPAIGN_GET_ALL_SIMPLE = 'CAMPAIGN_GET_ALL_SIMPLE'
const CAMPAIGN_UPDATE_STATUS = 'CAMPAIGN_UPDATE_STATUS'
const CAMPAIGN_UPDATE_DAILY_BUDGET = 'CAMPAIGN_UPDATE_DAILY_BUDGET'
const CAMPAIGN_SEARCH = 'CAMPAIGN_SEARCH'
const CAMPAIGN_EXPORT_TABLE = 'CLIENT_EXPORT_TABLE'
const CAMPAIGN_SET_ITEMS_PER_PAGE = 'CAMPAIGN_SET_ITEMS_PER_PAGE'
const CAMPAIGN_SET_FILTERS = 'CAMPAIGN_SET_FILTERS'

export const CAMPAIGN_ACTIONS = generateConstants({
  CAMPAIGN_GET_ALL,
  CAMPAIGN_EXPORT_TABLE,
  CAMPAIGN_SET_ITEMS_PER_PAGE,
  CAMPAIGN_GET_ALL_SIMPLE,
  CAMPAIGN_UPDATE_STATUS,
  CAMPAIGN_UPDATE_DAILY_BUDGET,
  CAMPAIGN_SEARCH,
  CAMPAIGN_SET_FILTERS,
})

// data-sources
const DATASOURCE_GET_ALL = 'DATASOURCE_GET_ALL'
const DATASOURCE_GET_LINK = 'DATASOURCE_GET_LINK'
const DATASOURCE_DELETE = 'DATASOURCE_DELETE'
const DATASOURCE_REFRESH = 'DATASOURCE_REFRESH'

export const DATASOURCE_ACTIONS = generateConstants({
  DATASOURCE_GET_ALL,
  DATASOURCE_GET_LINK,
  DATASOURCE_DELETE,
  DATASOURCE_REFRESH,
})

export const HISTORY_ACTIONS = generateConstants({
  GET_PAGE: 'HISTORY_GET_PAGE',
  SET_PAGE: 'HISTORY_SET_PAGE',
  SET_ITEMS_PER_PAGE: 'HISTORY_SET_ITEMS_PER_PAGE',
  SET_FILTERS: 'HISTORY_SET_FILTERS',
})

// notifications
const NOTIFICATION_GET_ALL = 'NOTIFICATION_GET_ALL'
const NOTIFICATION_ADD = 'NOTIFICATION_ADD'
const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'
const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE'
const NOTIFICATION_TURN_OFF_ALL = 'NOTIFICATION_TURN_OFF_ALL'

export const NOTIFICATION_ACTIONS = generateConstants({
  NOTIFICATION_GET_ALL,
  NOTIFICATION_ADD,
  NOTIFICATION_UPDATE,
  NOTIFICATION_DELETE,
  NOTIFICATION_TURN_OFF_ALL,
})

// team-members
const TEAMMEMBER_GET_ALL = 'TEAMMEMBER_GET_ALL'
const TEAMMEMBER_GET_ALL_REQUESTS = 'TEAMMEMBER_GET_ALL_REQUESTS'
const TEAMMEMBER_UPDATE_ROLE = 'TEAMMEMBER_UPDATE_ROLE'
const TEAMMEMBER_UPDATE_CLIENTS = 'TEAMMEMBER_UPDATE_CLIENTS'
const TEAMMEMBER_INVITE = 'TEAMMEMBER_INVITE'
const TEAMMEMBER_DELETE = 'TEAMMEMBER_DELETE'
const TEAMMEMBER_RESEND = 'TEAMMEMBER_RESEND'
const TEAMMEMBER_DELETE_INVITE = 'TEAMMEMBER_DELETE_INVITE'

export const TEAMMEMBER_ACTIONS = generateConstants({
  TEAMMEMBER_GET_ALL,
  TEAMMEMBER_GET_ALL_REQUESTS,
  TEAMMEMBER_UPDATE_ROLE,
  TEAMMEMBER_UPDATE_CLIENTS,
  TEAMMEMBER_INVITE,
  TEAMMEMBER_DELETE,
  TEAMMEMBER_RESEND,
  TEAMMEMBER_DELETE_INVITE,
})

export const SUMO_PLANS = generateConstants({
  sumoGetPlans: 'SUMO_GET_PLANS',
})

// invoices
const INVOICE_GET_ALL = 'INVOICE_GET_ALL'

export const INVOICE_ACTIONS = generateConstants({
  INVOICE_GET_ALL,
})

// plans
const PLAN_GET_ALL = 'PLAN_GET_ALL'
const PLAN_GET_CUSTOM = 'PLAN_GET_CUSTOM'
const PLAN_CREATE_REQUEST = 'PLAN_CREATE_REQUEST'

export const PLAN_ACTIONS = generateConstants({
  PLAN_GET_ALL,
  PLAN_GET_CUSTOM,
  PLAN_CREATE_REQUEST,
})

// subscriptions
const SUBSCRIPTION_GET_CURRENT = 'SUBSCRIPTION_GET_CURRENT'
const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL'
const SUBSCRIPTION_SET_CURRENT = 'SUBSCRIPTION_SET_CURRENT'
const PAYMENT_METHOD_GET = 'PAYMENT_METHOD_GET'
const PAYMENT_METHOD_ADD = 'PAYMENT_METHOD_ADD'

export const SUBSCRIPTION_ACTIONS = generateConstants({
  SUBSCRIPTION_GET_CURRENT,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_SET_CURRENT,
  PAYMENT_METHOD_GET,
  PAYMENT_METHOD_ADD,
})

export const LOCATION_SUBSCRIPTIONS = 'subscriptions'

// labels
const LABEL_GET_ALL = 'LABEL_GET_ALL'
const LABEL_ADD = 'LABEL_ADD'

export const LABEL_ACTIONS = generateConstants({
  LABEL_GET_ALL,
  LABEL_ADD,
})

// coupon
const COUPON_GET_INFO = 'COUPON_GET_INFO'
const COUPON_REMOVE = 'COUPON_REMOVE'

export const COUPON_ACTIONS = generateConstants({
  COUPON_GET_INFO,
  COUPON_REMOVE,
})

// table
export const TABLE_ACTIONS = {
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE: 'SET_PAGE',
  SET_FILTERED: 'SET_FILTERED',
  UNSET_FILTERED: 'UNSET_FILTERED',
  SET_CUSTOMIZE: 'SET_CUSTOMIZE',
  SET_SORTED: 'SET_SORTED',
  EMPTY_ACTION_URL: 'EMPTY_ACTION_URL',
}

// user notifications
const USER_NOTIFICATION_GET_ALL = 'USER_NOTIFICATION_GET_ALL'
const USER_NOTIFICATION_RESET_ALL = 'USER_NOTIFICATION_RESET_ALL'
const USER_NOTIFICATION_HAS_MORE = 'USER_NOTIFICATION_HAS_MORE'

export const USER_NOTIFICATIONS_ACTIONS = generateConstants({
  USER_NOTIFICATION_GET_ALL,
  USER_NOTIFICATION_RESET_ALL,
  USER_NOTIFICATION_HAS_MORE,
})
