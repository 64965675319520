import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useHistory, useParams } from 'react-router-dom'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'
import { saveAs } from 'file-saver'
import _isEmpty from 'lodash.isempty'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import BarChart from 'components/ui/Charts/BarChart'
import LineChart from 'components/ui/Charts/LineChart'
import DataTable from 'pages/Budgets/table'
import ModalConfirmDeactivation from 'pages/shared/modals/modalConfirmDeactivation'
import ModalConfirmActivation from 'pages/shared/modals/modalConfirmActivation'
import ModalConfirmDelete from 'pages/shared/modals/modalConfirmDelete'
import Widgets from 'components/ui/Widgets'
import ModalUpdate from '../../components/ModalUpdate'
import RefreshButton from 'components/ui/RefreshButton'

// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// actions
import {
  getSingleBudget,
  updateBudgetStatus,
  deleteBudget,
  getUrlForExportSingleBudget,
} from 'state-manager/actions/budgets'
import {
  updateCampaignStatus,
  updateCampaignDailyBudget,
} from 'state-manager/actions/campaigns'

// hooks
import _useToggle from 'hooks/use-toggle'
import _useDidUpdate from '../../hooks/lifecycle/use-did-update'
import useRefreshData from 'hooks/useRefreshData'

// constants
import { ACTIVE, EMPTY } from 'constants/status'

// routes
import routes from 'routes'

import { formatNumber } from 'utils/formatNumber'

// styles
import classes from 'pages/Budgets/Budgets.module.scss'

// state-manager
import { useAppDispatch, useAppSelector } from 'state-manager/store'
import { IBudgetContent } from 'state-manager/reducers/all-budgets'
import { BUDGET_ACTIONS } from 'state-manager/constants'
import { formatDateStr, isDateFormat } from 'helpers/formatDateStr'

const Budgets = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const [isUpdateModalOpen, toggleUpdateModalOpen] = _useToggle()
  const [deactivateModalOpen, toggleDeactivateModalOpen] = _useToggle()
  const [activateModalOpen, toggleActivateModalOpen] = _useToggle()
  const [deleteModalOpen, toggleDeleteModalOpen] = _useToggle()
  const [isLoading, setIsLoading] = useState(false)

  const [udPermissions, udCompany, budget, allBudgets] = useAppSelector(
    (state) => [
      state.userData.permissions,
      state.userData.company,
      state.singleBudget.data,
      state.allBudgets.content,
    ]
  )

  const dispatch = useAppDispatch()

  const { isDataNeedToRefresh, restoreTimer } = useRefreshData()

  const isGraphsData = Boolean(
      (budget?.calculated?.graphsData?.daily && typeof budget?.calculated?.graphsData?.daily === 'object' && Object.keys(budget?.calculated?.graphsData?.daily).length > 0) ||
            (Array.isArray(budget?.calculated?.graphsData?.daily) && budget?.calculated?.graphsData?.daily.length > 0) ||
            (budget?.calculated?.graphsData?.cumulative && typeof budget?.calculated?.graphsData?.cumulative === 'object' && Object.keys(budget?.calculated?.graphsData?.cumulative).length > 0) ||
            (Array.isArray(budget?.calculated?.graphsData?.cumulative) && budget?.calculated?.graphsData?.cumulative.length > 0)
  )

  const timelineData = useMemo(
    () =>
      isGraphsData
        ? Object.entries(budget.calculated.graphsData.daily).map((item) => ({
            label: isDateFormat(item[0]) ? formatDateStr(item[0]) : '-',
            actualData: item[1].actual,
            budgetData: item[1].predicted,
          }))
        : [],
    [budget?.calculated?.graphsData, isGraphsData]
  )

  const cumulativeTimelineData = useMemo(
    () =>
      isGraphsData
        ? Object.entries(budget.calculated.graphsData.cumulative).map(
            (item) => ({
              label: isDateFormat(item[0]) ? formatDateStr(item[0]) : '-',
              actualData: item[1].actual,
              budgetData: item[1].predicted,
            })
          )
        : [],
    [budget?.calculated?.graphsData?.cumulative, isGraphsData]
  )

  const handleOpenModal = () => {
    budget.status === ACTIVE
      ? toggleDeactivateModalOpen()
      : toggleActivateModalOpen()
  }

  const handleUpdateStatus = (shouldUpdateCampaigns = true) => {
    const updateData = {
      active: budget.status === ACTIVE ? 0 : 1,
      updateCampaigns: !!shouldUpdateCampaigns,
    }

    dispatch(updateBudgetStatus(budget.id, updateData)).then(() => {
      dispatch(getSingleBudget(id))
      budget.status === ACTIVE
        ? toggleDeactivateModalOpen()
        : toggleActivateModalOpen()
    })
  }

  const handleBackButton = () => history.replace(routes.dashboardBudgets)

  const currentBudgetData = allBudgets.find(({ id }) => id === budget.id)

  const renderWidgets = (data: IBudgetContent) => {
    const widgetData = [
      {
        value: formatNumber(data.budgetTarget),
        name: 'Budget',
        color: 'green',
        isPercent: false,
      },
      {
        value: formatNumber(data.spentToDate),
        name: 'Spend',
        color: 'blue',
        isPercent: false,
      },
      {
        value: data.pacing,
        name: 'Pacing',
        color: 'yellow',
        isPercent: true,
      },
    ]
    return <Widgets items={widgetData} />
  }

  const handleUpdateCampaignStatus = (
    campaignId: string,
    formData: { status: string }
  ) => {
    dispatch(updateCampaignStatus(campaignId, formData)).then(() => {
      dispatch(getSingleBudget(id))
    })
  }

  const handleDeleteBudget = () => {
    dispatch(deleteBudget(id)).then(() => {
      toggleDeleteModalOpen()
      history.push(routes.dashboardBudgets)
    })
  }

  const handleExportCsv = async () => {
    const res = await dispatch<{ url: string }>(
      getUrlForExportSingleBudget(id, 'csv')
    )
    saveAs(res.url)
  }

  const handleSaveBudgetBulk = async ({
    selectedValues,
    newStatus,
    newBudget,
  }: {
    selectedValues: Array<number>
    newStatus: string
    newBudget: string
  }) => {
    const data: Record<string, string> = {}
    if (newStatus !== EMPTY) {
      selectedValues.forEach((id) => {
        data[`campaigns[${id}][status]`] = newStatus
      })
    }
    if (newBudget) {
      selectedValues.forEach((id) => {
        data[`campaigns[${id}][dailyBudget]`] = newBudget
      })
    }
    if (Object.keys(data).length === 0) {
      return
    }
    const res = await reqH<{
      result?: {
        failure?: Array<string>
      }
    }>({
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      urlPrefix: 'campaign',
      url: 'status-budget/update',
    })

    await dispatch(getSingleBudget(id))

    toggleUpdateModalOpen()

    // TODO: check if we need to add 'data' after res.
    if (Array.isArray(res?.result?.failure) && res.result.failure.length) {
      notification.error(
        res.result.failure[0]?.message || 'Something went wrong'
      )
    } else {
      notification.success('Successfully updated')
    }
  }

  useEffect(() => {
    dispatch(getSingleBudget(id))
    setIsLoading(true)

    return () => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_DELETE_SINGLE.SYSTEM })
    }
    // Reminder: Don't touch id in dependencies
  }, [dispatch, id])

  useEffect(() => {
    if (
      !budget ||
      !Object.keys(budget).length ||
      budget?.campaigns?.length === 0
    )
      return
    const containsVideoType = budget?.campaigns.some(
      (item) => item.source === 'Google Ads' && item.channelType === 'VIDEO'
    )
    if (containsVideoType) {
      notification.error(
        'This budget contains one or multiple Video campaigns. The Google Ads API does not allow status changes for this campaign type hence Overspend Control will not be able to pause these campaigns. Changes to all other campaign types will function normally'
      )
    }
  }, [budget])

  _useDidUpdate(() => {
    if (!_isEmpty(budget) && !_isEmpty(budget.client)) {
      if (udCompany?.id) {
        if (udCompany.id !== budget.client.company_id) {
          history.push(routes.dashboardBudgets)
          // eslint-disable-next-line no-console
          console.info('Access denied or page not found')
        }
      }
    }

    setIsLoading(false)
  }, [budget])

  if (!budget || !Object.keys(budget).length) {
    return <div className="text-center">Budget not found</div>
  }
  const tableData = budget.campaigns

  return (
    <>
      <div>
        <div className="heading d-flex justify-content-between align-items-center flex-column-sm flex-column-md-down">
          <div className="d-flex align-items-center align-self-start-sm mb-lg-down-2 flex-wrap-sm">
            <SpriteIcon
              name="arrowLeft"
              size="md"
              className="mr-3 cursor-pointer"
              onClick={handleBackButton}
              dataCy="Go back"
            />
            <span
              className={clsx(
                classes.dotStatus,
                budget.status === ACTIVE && classes.dotStatusActive
              )}
            />
            <div className="fw-semibold fs-lg fs-sm-main color-black mx-3">
              {budget.name}
            </div>
            <div className="fw-semibold fs-lg fs-sm-main color-green mt-sm-2 w-sm-20 text-center">
              {budget.client.name}
            </div>
          </div>
          {udPermissions.canManageBudgets && (
            <div className="d-flex align-self-end-sm">
              <button
                type="button"
                className={clsx(classes.buttonCsv, 'btn', 'color-green-fade')}
                data-cy="export csv"
                onClick={handleExportCsv}>
                Export CSV
              </button>
              <button
                type="button"
                data-cy={budget.status === ACTIVE
                    ? 'Deactivate budget'
                    : 'Activate budget'}
                onClick={handleOpenModal}
                className={clsx(
                  classes.buttonStatus,
                  budget.status === ACTIVE
                    ? 'color-red-fade'
                    : 'color-green-fade',
                  'btn'
                )}>
                {budget.status === ACTIVE ? 'Deactivate' : 'Activate'}
              </button>
              <button
                type="button"
                className={clsx(classes.buttonEdit, 'btn')}
                data-cy="edit budget"
                onClick={() =>
                  history.push({
                    pathname: formatRoute(routes.editBudget, { id }),
                  })}>
                Edit
              </button>
              {budget.status !== ACTIVE && (
                <div onClick={() => toggleDeleteModalOpen()} role="button">
                  <div
                    className={clsx(classes.buttonEdit, 'btn color-red ml-2')}>
                    Delete
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`${classes.warning_text} mb-3`}>
          {budget?.campaigns.some(
            (item) =>
              item.source === 'Google Ads' && item.channelType === 'VIDEO'
          ) && (
            <p>
              Video campaigns in this budget cannot be paused in EDEE due to
              Google Ads API restrictions. Overspend Control will not work for
              these campaigns.
            </p>
          )}
        </div>
      </div>

      {currentBudgetData && renderWidgets(currentBudgetData)}

      {isGraphsData && (
        <div className="mb-3">
          <BarChart budgetData={timelineData} />
          <div className="mb-8" />
          <LineChart budgetData={cumulativeTimelineData} />
        </div>
      )}

      <RefreshButton
        isDataNeedToRefresh={isDataNeedToRefresh}
        onClick={() => {
          dispatch(getSingleBudget(id)).then(restoreTimer)
        }}
      />

      {!isLoading && (
        <DataTable
          data={tableData}
          updateCampaignStatus={handleUpdateCampaignStatus}
          budgetId={id}
          updateCampaignDailyBudget={(budgetId, campaignId, formatted) =>
            dispatch(updateCampaignDailyBudget(budgetId, campaignId, formatted))}
          canEdit={udPermissions.canManageBudgets}
          openUpdate={toggleUpdateModalOpen}
        />
      )}

      {isUpdateModalOpen && (
        <ModalUpdate
          data={tableData}
          onClose={toggleUpdateModalOpen}
          onSave={handleSaveBudgetBulk}
        />
      )}

      {deactivateModalOpen && (
        <ModalConfirmDeactivation
          entity="budget"
          onClick={handleUpdateStatus}
          onNoClick={() => handleUpdateStatus(false)}
          onClose={toggleDeactivateModalOpen}
        />
      )}
      {activateModalOpen && (
        <ModalConfirmActivation
          entity="budget"
          onClick={handleUpdateStatus}
          onClose={toggleActivateModalOpen}
        />
      )}
      {deleteModalOpen && (
        <ModalConfirmDelete
          entity="budget"
          onClick={handleDeleteBudget}
          onClose={toggleDeleteModalOpen}
        />
      )}
    </>
  )
}

export default Budgets
