import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state-manager/store'

// components
import Table, { cellType } from 'components/ui/Table/index'
import CellDataSourceIcon from 'components/ui/Table/Cells/CellDataSourceIcon'
import TableCellRemove from 'pages/DataSources/table-cell-remove'
import ModalConfirmDeactivation from 'pages/DataSources/ModalConfirmDeleteDataSource'
import TableCellRefresh from 'pages/DataSources/table-cell-refresh'

// hooks
import _useToggle from 'hooks/use-toggle'
import { _useGlobalDemo } from 'hooks/use-demo'

// routes
import routes from 'routes'

// helpers
import { isDemoAccount, userIdSelector } from 'helpers/demo'
import { ICustomColumn } from 'state-manager/reducers/table'

// constants
import { TABLE_ACTIONS } from 'state-manager/constants'

interface IData extends cellType {
  isConnected: boolean,
}

type DataTableProps = {
  data: IData[];
  refreshDataSource: (id: string) => void;
}

const demoDataSources = [
  {
    email: 'example_mail@gmail.com',
    id: 1,
    status: 'Success',
    type: 'google',
  },
  {
    email: 'example_mail_2@gmail.com',
    id: 2,
    status: 'Success',
    type: 'facebook',
  },
  {
    email: 'example_mail_3@gmail.com',
    id: 3,
    status: 'Success',
    type: 'microsoft',
  },
]

const DataSourceCell = ({ value }: { value: any }) => <CellDataSourceIcon value={value} />
const EmailCell = ({ value }: { value: any }) => <div className="d-flex align-items-center">{value}</div>
const AccessCell = ({ value }: { value: any }) => <div className={clsx(value === 'Success' ? 'color-green' : 'color-red', 'd-flex align-items-center')}>{value === 'Success' ? 'Success' : 'Error'}</div>

const DataTable: React.FC<DataTableProps> = ({
  data,
  refreshDataSource,
}) => {
  const [isModalOpen, toggleIsModalOpen] = _useToggle()
  const [deactivationDataSourceId, setDeactivationDataSourceId] = useState('')
  const history = useHistory()

  const userId = useSelector(userIdSelector)
  const isDemoEnabled = isDemoAccount(userId)

  const demoHOC = _useGlobalDemo()

  const dispatch = useAppDispatch()

  const tableData = isDemoEnabled ? demoDataSources : data.map((item) => ({
    ...item,
    status: item.isConnected ? 'Success' : 'Error',
  }))

  const handleDataSourceRemove = demoHOC((val) => {
    setDeactivationDataSourceId(val)
    toggleIsModalOpen()
  })

  const handleRefresh = demoHOC((id) => {
    refreshDataSource(id)
  })

  const columns: Array<ICustomColumn> = React.useMemo(
    () => [
      {
        name: 'type',
        Header: 'Data Source',
        filterType: 'select',
        Cell: DataSourceCell,
      },
      {
        name: 'email',
        Header: 'Email',
        Cell: EmailCell,
        style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      },
      {
        name: 'status',
        Header: 'Access',
        filterType: 'select',
        sortable: false,
        isHideSortOption: true,
        Cell: AccessCell,
      },
      {
        name: 'id',
        Header: '',
        width: 50,
        isHideSortOption: true,
        isHideFilter: true,
        Cell: ({ value }) => <TableCellRefresh id={value} onRefresh={handleRefresh} />,
      },
      {
        name: 'id',
        Header: '',
        width: 50,
        isHideSortOption: true,
        isHideFilter: true,
        Cell: ({ value }) => <TableCellRemove value={value} onRemove={handleDataSourceRemove} />,
      },
    ],
    //eslint-disable-next-line
    [],
  )

  useEffect(() => {
    dispatch({
      type: TABLE_ACTIONS.SET_SORTED,
      data: {
        sorted: [{
          id: 'email',
          desc: false,
        }],
        tableName: 'dataSources',
      },
    })
  }, [dispatch])

  return (
    <>
      <Table
        tableName="dataSources"
        data={tableData}
        columns={columns}
        title="Data Sources">
        <div
          role="button"
          className="btn mt-sm-1"
          data-cy="add data source"
          onClick={() => history.push(routes.addDataSource)}>
          Add Data Source
        </div>
      </Table>
      {isModalOpen && (
        <ModalConfirmDeactivation
          id={deactivationDataSourceId}
          onClose={toggleIsModalOpen}
        />
      )}
    </>
  )
}

export default DataTable
