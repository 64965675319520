import { connectRouter } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import { persistCombineReducers } from 'redux-persist'
import { History } from 'history'

// reducers
import userDataReducer, { IUserData } from 'state-manager/reducers/user-data'

import allBudgetsReducer, {
  IAllBudgets,
} from 'state-manager/reducers/all-budgets'
import allBudgetsChartReducer, {
  IAllBudgetsChart,
} from 'state-manager/reducers/all-budgets-chart'
import singleBudgetReducer, {
  ISingleBudget,
} from 'state-manager/reducers/single-budget'

import allClientReducer, {
  IAllClients,
} from 'state-manager/reducers/all-clients'
import allClientsChartReducer, {
  IAllClentsCharts,
} from 'state-manager/reducers/all-clients-chart'
import singleClientReducer, {
  ISingleClient,
} from 'state-manager/reducers/single-client'
import labelReducer, { ILabels } from 'state-manager/reducers/labels'

import campaignReducer, {
  IAllCampaigns,
  ICampaignRes,
} from 'state-manager/reducers/all-campaigns'
import dataSourceReducer, {
  IDataSource,
} from 'state-manager/reducers/data-sources'
import historyReducer, { IHistory } from 'state-manager/reducers/history'
import notificationsReducer, {
  INotifications,
} from 'state-manager/reducers/notifications'
import teamMemberReducer, {
  ITeamMember,
} from 'state-manager/reducers/team-members'

import invoiceReducer, { IInvoices } from 'state-manager/reducers/invoices'
import planReducer, { IPlans } from 'state-manager/reducers/plans'
import subscriptionReducer, {
  ISubscription,
} from 'state-manager/reducers/subscription'
import billingInfoReducer, {
  IBillingInfo,
} from 'state-manager/reducers/billing-info'
import couponReducer, { ICoupon } from 'state-manager/reducers/coupon'

import tableReducer, { ITables } from 'state-manager/reducers/table'

import userNotificationsReducer, {
  IUserNotifications,
} from 'state-manager/reducers/user-notifications'
import manageBudget, {
  IManageBudget,
} from 'state-manager/reducers/manage-budget'
import sumoPlansReducer, { ISumoPlan } from 'state-manager/reducers/sumoPlans'
import globalModalReducer, {
  IGlobalModal,
} from 'state-manager/reducers/globalModal'
import productFruitsReducer, {
  IProductFruits,
} from 'state-manager/reducers/productFruits'
import demoModalReducer, { IDemoModal } from 'state-manager/reducers/demo-modal'
import clientModalReducer, {
  IClientModal,
} from 'state-manager/reducers/client-modal'
import addTeamMemberModalReducer, {
  IAddTeamMemberModal,
} from 'state-manager/reducers/team-member-modal'
import countriesReducer, { ICountries } from 'state-manager/reducers/countries'
import currenciesReducer, {
  ICurrencies,
} from 'state-manager/reducers/currencies'
import campaigns, { ICampaigns } from 'state-manager/reducers/campaigns'

export interface IRootState {
  userData: IUserData
  allBudgets: IAllBudgets
  allBudgetsChart: IAllBudgetsChart
  singleBudget: ISingleBudget
  allClients: IAllClients
  allClientsChart: IAllClentsCharts
  singleClient: ISingleClient
  allCampaigns: ICampaignRes
  campaigns: ICampaigns
  dataSources: IDataSource
  history: IHistory
  notifications: INotifications
  teamMembers: ITeamMember
  invoices: IInvoices
  plans: IPlans
  subscriptions: ISubscription
  billingInfo: IBillingInfo
  labels: ILabels
  coupon: ICoupon | null
  userNotifications: IUserNotifications
  manageBudget: IManageBudget
  sumoPlans: ISumoPlan[]
  table: ITables
  globalModal: IGlobalModal
  productFruits: IProductFruits
  demoModal: IDemoModal
  clientModal: IClientModal
  addTeamMemberModal: IAddTeamMemberModal
  countries: ICountries
  currencies: ICurrencies
}

const reducers = {
  userData: userDataReducer,
  allBudgets: allBudgetsReducer,
  allBudgetsChart: allBudgetsChartReducer,
  singleBudget: singleBudgetReducer,
  allClients: allClientReducer,
  allClientsChart: allClientsChartReducer,
  singleClient: singleClientReducer,
  allCampaigns: campaignReducer,
  campaigns,
  dataSources: dataSourceReducer,
  history: historyReducer,
  notifications: notificationsReducer,
  teamMembers: teamMemberReducer,
  invoices: invoiceReducer,
  plans: planReducer,
  subscriptions: subscriptionReducer,
  billingInfo: billingInfoReducer,
  labels: labelReducer,
  coupon: couponReducer,
  userNotifications: userNotificationsReducer,
  manageBudget,
  sumoPlans: sumoPlansReducer,
  table: tableReducer,
  globalModal: globalModalReducer,
  productFruits: productFruitsReducer,
  demoModal: demoModalReducer,
  clientModal: clientModalReducer,
  addTeamMemberModal: addTeamMemberModalReducer,
  countries: countriesReducer,
  currencies: currenciesReducer,
}

const whitelist = ['userData', 'labels', 'table', 'countries', 'currencies']

const filterTransforms = []
for (const reducer in reducers) {
  filterTransforms.push(createBlacklistFilter(reducer, ['responseWaiting']))
}

const transforms = [...filterTransforms]
const version = 'v-6'
const persistConfig = {
  key: `root-${version}`,
  storage,
  whitelist,
  transforms,
}

export default (history: History<unknown>) =>
  persistCombineReducers(persistConfig, {
    router: connectRouter(history),
    ...reducers,
  })
