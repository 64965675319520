import React, { useRef, useState } from 'react'
import clsx from 'clsx'

// components
import Table from 'components/ui/Table/index'
import CellDataSourceIcon from 'components/ui/Table/Cells/CellDataSourceIcon'

// hooks
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// constants
import { CAMPAIGN_ACTIONS, TABLE_ACTIONS } from 'state-manager/constants'
import { ACTIVE } from 'constants/status'

//utils
import { delayAction } from 'utils/delayAction'

//services
import { getAllCampaigns } from 'state-manager/actions/campaigns'

// types
import { SortingRule } from 'react-table'
import { IFilter } from 'state-manager/reducers/all-campaigns'

type TStatus = 'active' | 'inactive' | 'paused'

type DataTableType = {
  exportCampaigns: () => void
  isLoading?: boolean
}

const allocationDataArr = [
  { id: 'Allocated', value: 1 },
  { id: 'Unallocated', value: 0 },
]

function containsAllObjects<T>(array: T[], subset: T[]): boolean {
  return subset.every((subItem) =>
    array.some((item) => JSON.stringify(item) === JSON.stringify(subItem))
  )
}

const getAllocationStr = (value: boolean) =>
  value ? 'Allocated' : 'Unallocated'

const DELAY = 500

const StatusCell = ({ value }: { value: TStatus }) => (
  <span
    className={clsx(
      value === ACTIVE ? 'color-green' : 'color-red',
      'text-capitalize'
    )}
  >
    {value}
  </span>
)

const unallocatedActiveCampaignsFilters = [
  { id: 'allocated', value: '0' },
  { id: 'campaignStatus', value: 'active' },
]

const DataTable: React.FC<DataTableType> = ({
  exportCampaigns,
  isLoading = false,
}) => {
  const dispatch = useAppDispatch()
  const timer = useRef<NodeJS.Timeout>(null)

  const [sortOptions, setSortOptions] = useState<SortingRule[]>([])

  const {
    pages,
    currentPage,
    itemsPerPage,
    lastPage,
    filters,
    statusList,
    dataSourceList,
    currenciesList,
    spend7dSpendList,
  } = useAppSelector((state) => state.allCampaigns)

  const columns = React.useMemo(
    () => [
      {
        name: 'campaignName',
        Header: 'Campaign Name',
        width: 200,
        style: {
          whiteSpace: 'unset',
          wordBreak: 'break-all',
          textAlign: 'center',
        },
        visible: true,
      },
      {
        name: 'campaignStatus',
        Header: 'Campaign Status',
        width: 150,
        visible: true,
        Cell: StatusCell,
        Filter: ({ onChange, filter }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
            data-cy="campaign status filter"
          >
            <option value="all" data-cy="filter show all">
              All
            </option>
            {statusList.map((item) => (
              <option
                key={item}
                value={item}
                data-cy={`filter ${item}`}
                style={{ textTransform: 'capitalize' }}
              >
                {item}
              </option>
            ))}
          </select>
        ),
      },
      {
        name: 'campaignId',
        Header: 'Campaign ID',
        width: 150,
        visible: true,
      },
      {
        name: 'currency',
        Header: 'Currency',
        width: 120,
        visible: true,
        Cell: ({ value }) => {
          const currencyObj = currenciesList.find((el) => el.code === value)

          return (
            <span>
              {currencyObj?.symbol} {value}
            </span>
          )
        },
        Filter: ({ onChange, filter }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
            data-cy="currency filter"
          >
            <option value="all" data-cy="filter show all">
              All
            </option>
            {currenciesList.map((item) => (
              <option
                key={item.code}
                value={item.code}
                data-cy={`filter ${item}`}
              >
                {item.symbol} {item.code}
              </option>
            ))}
          </select>
        ),
      },
      {
        name: 'spend7Days',
        Header: 'Spend (Previous 7 Days)',
        width: 200,
        visible: true,
        Filter: ({ onChange, filter }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
            data-cy="spend 7 days filter"
          >
            <option value="all" data-cy="spend 7 days show all">
              All
            </option>
            {spend7dSpendList.map((item) => (
              <option
                key={item}
                value={item}
                data-cy={`filter ${item}`}
                style={{ textTransform: 'capitalize' }}
              >
                {item}
              </option>
            ))}
          </select>
        ),
      },
      {
        name: 'allocated',
        Header: 'Allocation',
        width: 120,
        visible: true,
        Cell: ({ value }) => {
          const formattedValue = getAllocationStr(value)

          return <span>{formattedValue}</span>
        },
        Filter: ({ onChange, filter }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
            data-cy="allocated filter"
          >
            <option value="all" data-cy="filter show all">
              All
            </option>
            {allocationDataArr.map((item) => (
              <option
                key={item.id}
                value={item.value}
                data-cy={`filter ${item.id}`}
                style={{ textTransform: 'capitalize' }}
              >
                {item.id}
              </option>
            ))}
          </select>
        ),
      },
      {
        name: 'budgetName',
        Header: 'Budget Name',
        width: 264,
        visible: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'normal',
        },
        className: 'contentOverflow',
      },
      {
        name: 'clientName',
        Header: 'Client Name',
        width: 264,
        visible: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'normal',
          textAlign: 'center',
        },
      },
      {
        name: 'dataSourceType',
        Header: 'Data Source',
        width: 150,
        visible: true,
        // eslint-disable-next-line
        Cell: ({ value }) => {
          // eslint-disable-next-line react/prop-types
          const formattedValue = value
            .replace(' ', '')
            .replace('Ads', '')
            .toLowerCase()

          return <CellDataSourceIcon value={formattedValue} />
        },
        Filter: ({ onChange, filter }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
            data-cy="data source filter"
          >
            <option value="all" data-cy="filter show all">
              All
            </option>
            {dataSourceList.map((item) => (
              <option
                key={item}
                value={item}
                data-cy={`filter ${item}`}
                style={{ textTransform: 'capitalize' }}
              >
                {item}
              </option>
            ))}
          </select>
        ),
      },
      {
        name: 'accountName',
        Header: 'Account Name',
        width: 200,
        visible: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'normal',
        },
      },
      {
        name: 'accountId',
        Header: 'Account ID',
        width: 150,
        visible: true,
      },
    ],
    [statusList, dataSourceList, currenciesList]
  )

  const handleFilterBtnClick = () => {
    let currFiltered: IFilter[] = []
    if (!containsAllObjects(filters, unallocatedActiveCampaignsFilters))
      currFiltered = unallocatedActiveCampaignsFilters

    dispatch({
      type: TABLE_ACTIONS.SET_FILTERED,
      data: {
        tableName: 'dashboardCampaigns',
        filtered: currFiltered,
      },
    })
    dispatch({
      type: CAMPAIGN_ACTIONS.CAMPAIGN_SET_FILTERS.SUCCESS,
      data: currFiltered,
    })
    dispatch(getAllCampaigns(1, itemsPerPage, currFiltered, sortOptions[0]))
  }

  const customButtons = [
    {
      name: 'Unallocated Active Campaigns',
      onClick: handleFilterBtnClick,
      isActive: containsAllObjects(filters, unallocatedActiveCampaignsFilters)
    },
  ]

  return (
    <Table
      manual
      persistable
      tableName="dashboardCampaigns"
      customizable
      exportHandle={exportCampaigns}
      title="Campaigns"
      columns={columns}
      data={pages[currentPage] ?? []}
      isLoading={isLoading}
      pagination={{
        totalPages: lastPage,
        currentPage,
      }}
      pageSize={itemsPerPage}
      isServerSidePagination
      onServerSidePagination={({ nextPage }) => {
        dispatch(
          getAllCampaigns(nextPage + 1, itemsPerPage, filters, sortOptions[0])
        )
      }}
      onServerSidePageSizeChange={(itemsPerPage) => {
        dispatch({
          type: CAMPAIGN_ACTIONS.CAMPAIGN_SET_ITEMS_PER_PAGE.SUCCESS,
          data: itemsPerPage,
        })
        dispatch(getAllCampaigns(1, itemsPerPage, filters, sortOptions[0]))
      }}
      onFilter={(filters) => {
        const trimmedFilters = filters.reduce((acc, filter) => {
          const value =
            typeof filter.value === 'string'
              ? filter.value.trimStart()
              : filter.value
          if (value) acc.push({ ...filter, value })
          return acc
        }, [] as Array<IFilter>)

        delayAction(
          () => {
            dispatch({
              type: CAMPAIGN_ACTIONS.CAMPAIGN_SET_FILTERS.SUCCESS,
              data: trimmedFilters,
            })
            dispatch(
              getAllCampaigns(1, itemsPerPage, trimmedFilters, sortOptions[0])
            )
          },
          DELAY,
          timer
        )
      }}
      sortedOptions={sortOptions}
      onSortedChange={(newSorter) => {
        setSortOptions(newSorter)

        delayAction(
          () => {
            dispatch(getAllCampaigns(1, itemsPerPage, filters, newSorter[0]))
          },
          DELAY,
          timer
        )
      }}
      customButtons={customButtons}
    />
  )
}

export default DataTable
