import React from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

// components
import SpriteIcon from 'components/ui/SpriteIcon'

import { CLIENT_MODAL } from 'state-manager/constants'

// routes
import routes from 'routes'

import { useAppSelector } from 'state-manager/store'

// styles
import classes from 'components/Sidebar/Sidebar.module.scss'
import { showGlobalModal } from 'state-manager/actions/global-modal'

const Sidebar: React.FC<{
  isOpen: boolean
  onClick: (data?: any) => void
}> = ({
  isOpen,
  onClick,
}) => {
  const history = useHistory()

  const [
    udPermissions,
    clients,
    dataSources,
  ] = useAppSelector((state) => ([
    state.userData.permissions,
    state.allClients.data,
    state.dataSources.data
  ]))

  const dispatch = useDispatch()

  const isDataSources = dataSources.length
  const isClients = clients.length

  const handleAddBudget = () => {
    if (isOpen) {
      onClick()
    }
    if (isClients) {
      history.push(routes.addBudget)
    } else {
      dispatch(showGlobalModal({
        title: 'Add Budget',
        header: 'You cannot perform this action',
        description: 'To add the budget please add at least one client',
      }))
    }
  }

  const handleAddClientOpen = () => {
    if (isOpen) {
      onClick()
    }
    if (isDataSources) {
      dispatch({
        type: CLIENT_MODAL.SHOW,
      })
    } else {
      dispatch(showGlobalModal({
        title: 'Add Client',
        header: 'You cannot perform this action',
        description: 'To add the client please add at least one data source',
      }))
    }
  }

  history.listen((location) => {
    if (location.pathname && !location.pathname.includes(routes.settings)) {
      onClick('shouldClose')
    }
  })

  return (
    <>
      <aside
        onClick={(e) => {
          // @ts-expect-error e.target has className field
          if (typeof e.target.className === 'string' && e.target.className.includes(classes.sidebarContainer)) {
            onClick(true)
          }
        }}
        className={clsx(
          classes.sidebarContainer,
          isOpen && classes.sidebarContainerOpened,
          'd-flex flex-column',
        )}>
        <div
          className={clsx(
            classes.sidebarInnerContainer,
            'd-flex flex-column',
          )}>
          <div className="d-flex">
            {isOpen && (
              <div role="button" className={clsx(classes.backButtonContainer, 'd-flex align-items-center p-3')} onClick={onClick} data-cy="closeMenu">
                <SpriteIcon name="arrowLeftWhite" size="smd" />
              </div>
            )}
            <div className={clsx(
              classes.logoContainer,
              'd-flex justify-content-center py-2',
            )}>
              <NavLink
                exact
                data-cy="logo"
                to={routes.dashboardClients}>
                <SpriteIcon name="logo" />
              </NavLink>
            </div>
          </div>
          <div
            className={clsx(
              classes.menuContainer,
              'px-2 pt-3',
            )}>
            <label>dashboard</label>
            <ul className="my-1">
              <li className="d-flex">
                <NavLink
                  to={routes.dashboardClients}
                  className={clsx(classes.menuLink)}
                  data-cy="clients"
                  activeClassName={clsx(classes.activeLink)}>
                  Clients
                </NavLink>
                <NavLink
                  to={routes.dashboardBudgets}
                  className={clsx(classes.menuLink)}
                  data-cy="budgets"
                  activeClassName={clsx(classes.activeLink)}>
                  Budgets
                </NavLink>
                <NavLink
                  to={routes.dashboardCampaigns}
                  className={clsx(classes.menuLink)}
                  data-cy="budgets"
                  activeClassName={clsx(classes.activeLink)}>
                  Campaigns
                </NavLink>
              </li>
            </ul>
            {udPermissions.canManageClients && <label>activity</label>}
            <ul className="my-1">
              <li className="d-flex">
                {udPermissions.canManageClients && (
                  <NavLink
                    to={routes.changesHistory}
                    data-cy="changeHistory"
                    className={clsx(classes.menuLink)}
                    activeClassName={clsx(classes.activeLink)}>
                    Change history
                  </NavLink>
                )}
              </li>
            </ul>

            {udPermissions.canManageDataSources && (
              <label>setup</label>
            )}
            <ul className="my-1">
              <li className="d-flex">
                {udPermissions.canInviteUsers && (
                  <NavLink
                    to={routes.teamMembers}
                    data-cy="team members"
                    className={clsx(classes.menuLink)}
                    activeClassName={clsx(classes.activeLink)}>
                    Team members
                  </NavLink>
                )}
                {udPermissions.canManageDataSources && (
                  <NavLink
                    to={routes.dataSources}
                    data-cy="data sources"
                    className={clsx(classes.menuLink)}
                    activeClassName={clsx(classes.activeLink)}>
                    Data sources
                  </NavLink>
                )}
                {udPermissions.canManageSubscriptions && (
                  <NavLink
                    to={routes.invoices}
                    data-cy="billing"
                    className={clsx(classes.menuLink)}
                    activeClassName={clsx(classes.activeLink)}>
                    Billing
                  </NavLink>
                )}
                {udPermissions.canSetUpNotifications && (
                  <NavLink
                    to={routes.notifications}
                    data-cy="notifications"
                    className={clsx(classes.menuLink)}
                    activeClassName={clsx(classes.activeLink)}>
                    Notifications
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
          {(udPermissions.canManageClients || udPermissions.canManageBudgets) && (
            <div
              className={clsx(
                classes.sidebarButtonsContainer,
                'd-flex justify-content-between pb-2 px-2',
              )}>
              {udPermissions.canManageClients && (
                <div
                  role="button"
                  className={clsx('btn btn-small mx-1', isOpen && 'full-width', !isDataSources && 'disabled')}
                  data-cy="add client"
                  onClick={handleAddClientOpen}>
                  Add Client
                </div>
              )}
              {udPermissions.canManageBudgets && (
                <div
                  role="button"
                  className={clsx('btn btn-small mx-1', isOpen && 'full-width', !isClients && 'disabled')}
                  data-cy="add budget"
                  onClick={handleAddBudget}>
                  Add Budget
                </div>
              )}
            </div>
          )}
        </div>
      </aside>
    </>
  )
}

export default Sidebar
