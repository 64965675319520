// actions
import { getAllBudgets } from 'state-manager/actions/budgets'

// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { CLIENT_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'
import { formatDateStr, isDateFormat } from 'helpers/formatDateStr'

const urlPrefix = 'client'

export const getAllClients = (withoutLoader?: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: CLIENT_ACTIONS.CLIENT_GET_ALL.ATTEMPT })

  return reqH({
    method: 'GET',
    urlPrefix: 'clients',
    url: 'table',
    withoutLoader,
  })
    .then((res) => {
      dispatch({
        type: CLIENT_ACTIONS.CLIENT_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: CLIENT_ACTIONS.CLIENT_GET_ALL.ERROR })
    })
}

export const getAllClientsChartData = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    urlPrefix: 'clients',
    url: 'graph',
  })
    .then((res) => {
      dispatch({
        type: CLIENT_ACTIONS.CLIENT_GET_CHART_DATA.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: CLIENT_ACTIONS.CLIENT_GET_CHART_DATA.ERROR })
    })
}

export const getSingleClient = (id: string | number) => (dispatch: AppDispatch) => {
  dispatch({ type: CLIENT_ACTIONS.CLIENT_GET_SINGLE.ATTEMPT })

  return reqH({
    method: 'GET',
    urlPrefix,
    url: id,
  })
    .then((res) => {
      const result = res.data.result

      reqH({
        method: 'GET',
        urlPrefix: 'budget',
        url: 'table',
        params: {
          clientId: id,
        },
      })
        .then((res) => {
          result.budgetInfo = res.data.result

          dispatch({
            type: CLIENT_ACTIONS.CLIENT_GET_SINGLE.SUCCESS,
            data: result,
          })
        })
    })
    .catch(() => {
      dispatch({ type: CLIENT_ACTIONS.CLIENT_GET_SINGLE.ERROR })
    })
}

export const getSingleClientCharts = (id: string) => (
  reqH({
    method: 'GET',
    urlPrefix,
    url: `${id}/summary-graphs`,
  })
    .then(({ data }) => {
      if (typeof data?.result !== 'object') {
        return { parsedDaily: [], parsedCumulative: [] }
      }
      const { daily, cumulative } = data.result

      const parseToFixed = (value) =>
        (typeof value === 'number' ? value.toFixed(2) : 0)

      const parsedDaily = daily.map(({ actual, predicted, date }) => ({
        actualData: parseToFixed(actual),
        budgetData: parseToFixed(predicted),
        label: isDateFormat(date) ? formatDateStr(date) : '-',
      }))

      const parsedCumulative = cumulative.map(({ actual, predicted, date }) => ({
        actualData: parseToFixed(actual),
        budgetData: parseToFixed(predicted),
        label: isDateFormat(date) ? formatDateStr(date) : '-',
      }))

      return { parsedDaily, parsedCumulative }
    })
)

export const getUrlForExportClientTable = (format: string, isPrevious: boolean) => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  urlPrefix: 'clients',
  url: `export/${format}`,
  params: {
    previous: +isPrevious,
  },
})
  .then((res) => {
    dispatch({
      type: CLIENT_ACTIONS.CLIENT_EXPORT_TABLE.SUCCESS,
      data: res.data.result,
    })
    return res.data.result
  })
  .catch(() => {
    dispatch({ type: CLIENT_ACTIONS.CLIENT_EXPORT_TABLE.ERROR })
  })

export const updateClientStatus = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  urlPrefix,
  url: 'update-status',
  data,
})
  .then(() => {
    dispatch(getSingleClient(data.clientId))
    dispatch(getAllClients())
    dispatch(getAllBudgets())
    notification.success('Client status successfully updated!')
  })
  .finally(() => {
    dispatch({ type: CLIENT_ACTIONS.CLIENT_UPDATE_STATUS.SYSTEM })
  })

export const addClient = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: urlPrefix,
  data,
})
  .then(() => {
    dispatch(getAllClients())
    notification.success('Client successfully added!')
  })
  .finally(() => {
    dispatch({ type: CLIENT_ACTIONS.CLIENT_ADD.SYSTEM })
  })

export const editClient = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    urlPrefix,
    url: 'update',
    data,
  })
    .then(() => {
      notification.success('Client successfully edited!')
      dispatch(getSingleClient(data.clientId))
      dispatch(getAllClients())
    })
    .finally(() => {
      dispatch({ type: CLIENT_ACTIONS.CLIENT_EDIT.SYSTEM })
    })
}

export const deleteClient = (id: string) => (dispatch: AppDispatch) => reqH({
  method: 'DELETE',
  urlPrefix,
  url: id,
})
  .then(() => {
    notification.success('Client successfully deleted!')
    dispatch(getAllClients())
  })
  .finally(() => {
    dispatch({ type: CLIENT_ACTIONS.CLIENT_DELETE_SINGLE.SYSTEM })
  })
