import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'

// components
import DataTable from 'pages/Campaigns/table'

// actions
import {
  getAllCampaigns,
  getUrlForExportCampaignTable,
} from 'state-manager/actions/campaigns'

// hooks
import { useAppSelector } from 'state-manager/store'

const Campaigns: React.FC = () => {
  const [campaigns, isLoading] = useAppSelector((state) => [
    state.allCampaigns,
    state.allCampaigns.responseWaiting,
  ])

  const { globalPageSize, globalFiltered, globalSorted } = useAppSelector(
    ({ table }) => ({
      globalPageSize: table.dashboardCampaigns?.pageSize,
      globalFiltered: table.dashboardCampaigns?.filtered,
      globalSorted: table.dashboardCampaigns?.sorted,
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!Object.keys(campaigns?.pages).length && !isLoading) {
      dispatch(
        getAllCampaigns(1, globalPageSize, globalFiltered, globalSorted?.[0])
      )
    }
  }, [
    campaigns?.pages,
    dispatch,
    isLoading,
    globalPageSize,
    globalFiltered,
    globalSorted,
  ])

  const handleExportCampaignsButton = () => {
    dispatch(getUrlForExportCampaignTable('csv')).then((res) => saveAs(res.url))
  }

  return (
    <DataTable
      exportCampaigns={handleExportCampaignsButton}
      isLoading={isLoading}
    />
  )
}

export default Campaigns
