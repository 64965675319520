import React from 'react'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider, ReactReduxContext } from 'react-redux'

/* styles. must be here to not override scss modules */
import 'react-toastify/dist/ReactToastify.css'
import 'styles/index.scss'

/**/
import { ReactComponent as Sprite } from 'assets/sprite.svg'

/* errors */
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import rollbarInstance from 'errors/rollbar/Rollbar'

/* store */
import { store, persistor, history } from 'state-manager/store'

/* app */
import App from './App'

const renderApp = (App) => {
  ReactDOM.render(
      <RollbarProvider instance={rollbarInstance}>
        <RollbarErrorBoundary>
          <Provider store={store} context={ReactReduxContext}>
            <PersistGate persistor={persistor}>
              <ConnectedRouter history={history} context={ReactReduxContext}>
                <Sprite />
                <App />
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        </RollbarErrorBoundary>
      </RollbarProvider>,
    document.getElementById('root'),
  )
}

renderApp(App)
