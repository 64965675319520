export default {
  modalSignIn: ['/sign-in', '/signin', '/login'],
  modalSignUp: ['/sign-up', '/signup', '/register'],
  modalTwoFactorCode: '/two-factor',
  modalChangePassword: '/password-reset/:token',
  modalForgotPassword: '/forgot-password',
  modalEmailSent: '/email-sent',
  modalPasswordChanged: '/password-changed',
  modalEmailVerification: '/email-verification',
  modalVerification: '/verification',
  impersonate: '/auth/admin/impersonate/:token',
  appSumoRegistration: '/appsumo-sign-up',
  autoAuth: '/authorization',

  panel: '/panel',
  confirmEmail: '/panel/confirm-email',
  subscriptions: '/panel/subscriptions/:token?',
  billing: '/panel/billing',
  invoices: '/panel/invoices',
  dashboardClients: '/panel/dashboard/clients',
  dashboardClientsChart: '/panel/dashboard/clients/chart',
  dashboardBudgets: '/panel/dashboard/budgets',
  dashboardBudgetsChart: '/panel/dashboard/budgets/chart',
  dashboardCampaigns: '/panel/dashboard/campaigns',
  clients: '/panel/clients/:id',
  budgets: '/panel/budgets/:id',
  campaigns: '/panel/campaigns',
  settings: '/panel/settings',
  changesHistory: '/panel/changes-history',
  teamMembers: '/panel/team-members',
  dataSources: '/panel/data-sources',
  notifications: '/panel/notifications',
  modalAddClient: '/panel/add-client',
  modalEditClient: '/panel/edit-client/:id',
  addBudget: '/panel/add-budget',
  // addBudgetV2: '/panel/v2/add-budget',
  editBudget: '/panel/edit-budget/:id',
  addDataSource: '/panel/add-data-source',
  addDataSourceSuccess: '/panel/add-data-source-results',

  // shows subscription form outside dashboard
  subscriptionsIframe: '/subscriptions-iframe',
}
