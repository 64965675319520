import { Column, Filter, SortingRule } from 'react-table'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { TABLE_ACTIONS } from 'state-manager/constants'
import { IFilter } from './all-campaigns'

export type persistableTableNames =
  | 'singleBudget'
  | 'singleClient'
  | 'dashboardBudgets'
  | 'dashboardClients'
  | 'dashboardCampaigns'
  | 'updateBulk'

export interface ICustomColumn extends Column {
  name: string
  filterType?: string
  visible?: boolean
  isHideSortOption?: boolean
  isHideFilter?: boolean
  customOptions?: Array<string>
  filterTypeColoredCustom?: boolean
  filterColoredValues?: {
    min: number
    between: {
      min: number
      max: number
    }
    max: number
    isInvertedColors?: boolean
  }
  filterWithoutCell?: boolean
  filterTypeColored?: boolean
  integer?: boolean
}

type ITable = {
  pageSize?: number
  page?: number
  filtered?: Array<Filter>
  columnsState?: Array<ICustomColumn>
  sorted?: Array<SortingRule>
}

export type ITables = {
  [key in persistableTableNames]: ITable | null
}

const initialState: ITables = {
  dashboardBudgets: null,
  dashboardClients: null,
  dashboardCampaigns: null,
  singleBudget: null,
  singleClient: null,
  updateBulk: null,
}

type actionType = {
  type:
    | typeof TABLE_ACTIONS.SET_PAGE_SIZE
    | typeof TABLE_ACTIONS.SET_PAGE
    | typeof TABLE_ACTIONS.SET_FILTERED
    | typeof TABLE_ACTIONS.UNSET_FILTERED
    | typeof TABLE_ACTIONS.SET_CUSTOMIZE
    | typeof TABLE_ACTIONS.SET_SORTED
  data: Partial<ITable> & { tableName: persistableTableNames }
}

export default (state = initialState, action: actionType) => {
  switch (action.type) {
    case TABLE_ACTIONS.SET_PAGE_SIZE:
      return updateObject(state, {
        [action.data.tableName]: {
          ...state[action.data.tableName],
          pageSize: action.data.pageSize,
        },
      })

    case TABLE_ACTIONS.SET_PAGE:
      return updateObject(state, {
        [action.data.tableName]: {
          ...state[action.data.tableName],
          page: action.data.page,
        },
      })

    case TABLE_ACTIONS.SET_FILTERED: {
      const trimmedFilters = action.data.filtered.reduce((acc, filter) => {
        const value =
          typeof filter.value === 'string' ? filter.value.trimStart() : filter.value
        if (value) acc.push({ ...filter, value })
        return acc
      }, [] as Array<IFilter>)
      return updateObject(state, {
        [action.data.tableName]: {
          ...state[action.data.tableName],
          filtered: trimmedFilters,
        },
      })
    }

    case TABLE_ACTIONS.UNSET_FILTERED:
      return updateObject(state, {
        [action.data]: {
          ...state[action.data],
          filtered: [],
        },
      })

    case TABLE_ACTIONS.SET_CUSTOMIZE:
      return updateObject(state, {
        [action.data.tableName]: {
          ...state[action.data.tableName],
          columnsState: action.data.columns,
        },
      })

    case TABLE_ACTIONS.SET_SORTED:
      return updateObject(state, {
        [action.data.tableName]: {
          ...state[action.data.tableName],
          sorted: action.data.sorted,
        },
      })

    default:
      return state
  }
}
