import Rollbar from 'rollbar'
import { ENV, ROLLBAR_TOKEN } from 'constants/main'

const rollbarInstance = new Rollbar({
    accessToken: ROLLBAR_TOKEN,
    environment: ENV || 'development',
    captureUncaught: true,
    captureUnhandledRejections: true,
})

export default rollbarInstance
