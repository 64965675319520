import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CAMPAIGN_ACTIONS } from 'state-manager/constants'

export interface IFilter {
  id: string
  value: string
}

export interface ICampaigns {
  campaignName: string
  campaignStatus: string
  campaignId: string
  currency: string
  spend7Days: number | null
  allocated: boolean
  budgetName: string
  clientName: string
  dataSourceType: string
  accountName: string
  accountId: number
}

export interface ICurrency {
  code: string
  symbol: string
}

export interface IAllCampaigns {
  currencies_list: ICurrency[]
  current_page: number
  data: Array<ICampaigns>
  data_source_list: Array<string>
  from: number
  to: number
  last_page: number
  max_7d_spend: number
  per_page: number
  status_list: string[]
  total: number
}

export interface ICampaignRes {
  pages: {
    [key: number]: ICampaigns
  }
  dataSourceList: string[]
  statusList: string[]
  currenciesList: ICurrency[]
  spend7dSpendList: string[]
  responseWaiting: boolean
  filters: Array<IFilter>
  isLoaded: boolean
  currentPage: number
  lastPage: number
  itemsPerPage: number
}

const initialState: ICampaignRes = {
  pages: {},
  dataSourceList: [],
  responseWaiting: false,
  currentPage: 1,
  lastPage: 1,
  itemsPerPage: 10,
  statusList: [],
  currenciesList: [],
  filters: [],
  spend7dSpendList: [],
  isLoaded: false,
}

export const dataSimpleTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      source: PropTypes.string,
      status: PropTypes.string,
      account: PropTypes.string,
      name: PropTypes.string,
      currencyId: PropTypes.number,
      dailyBudget: PropTypes.number,
    })
  ),
  pagination: PropTypes.shape({
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    last: PropTypes.bool,
    first: PropTypes.bool,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    numberOfElements: PropTypes.number,
  }),
}

export const types = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      pacing: PropTypes.number,
      suggetedDailyBudget: PropTypes.number,
      spentToDate: PropTypes.number,
      spentYesterday: PropTypes.number,
      spentSameDayLastWeek: PropTypes.number,
      dailyBudget: PropTypes.number,
    })
  ),
  dataSimple: PropTypes.shape({
    content: dataSimpleTypes.content,
    pagination: dataSimpleTypes.pagination,
  }),
  // dataSimple: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number,
  //   source: PropTypes.string,
  //   status: PropTypes.string,
  //   // account: PropTypes.object,
  //   name: PropTypes.string,
  // })),
}

function getRoundedRanges(max: number): string[] {
  if (max === 0) return []
  const step = max / 4
  const ranges: string[] = []

  for (let i = 0; i < 4; i++) {
    const start = Math.round(i * step)
    const end = Math.round((i + 1) * step)
    ranges.push(`${start}-${end}`)
  }

  return ranges
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.SUCCESS: {
      const {
        data,
        current_page: currentPage,
        last_page: lastPage,
        per_page: itemsPerPage,
        status_list: statusList,
        data_source_list: dataSourceList,
        currencies_list: currenciesList,
        max_7d_spend,
      } = action.data
      return updateObject(state, {
        pages: {
          ...state.pages,
          [currentPage]: data,
        },
        dataSourceList,
        currentPage,
        lastPage,
        itemsPerPage,
        statusList,
        currenciesList,
        spend7dSpendList: getRoundedRanges(max_7d_spend),
        responseWaiting: false,
        isLoaded: true,
      })
    }
    case CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CAMPAIGN_ACTIONS.CAMPAIGN_SET_ITEMS_PER_PAGE.SUCCESS: {
      return {
        ...initialState,
        filters: state.filters,
        itemsPerPage: action.data,
      }
    }
    case CAMPAIGN_ACTIONS.CAMPAIGN_SET_FILTERS.SUCCESS: {
      return {
        ...state,
        filters: action.data,
      }
    }

    default:
      return state
  }
}
