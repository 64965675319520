import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, Redirect, useLocation, Link } from 'react-router-dom'
// @ts-expect-error there is no types for product-fruits
import ProductFruits from 'react-product-fruits'

import { useAppSelector } from 'state-manager/store'

// routes
import routes from 'routes'

// pages
import ConfirmEmail from 'pages/ConfirmEmail'
import Billing from 'pages/Billing'
import Subscriptions from 'pages/Subscriptions'
import Invoices from 'pages/Invoices'

import TeamMembers from 'pages/TeamMembers'

import DataSources from 'pages/DataSources'
import AddDataSources from 'pages/AddDataSources'
import AddDataSourceSuccess from 'pages/DataSources/ConfirmDataSourceSuccess'

import Notifications from 'pages/Notifications'

import ChangesHistory from 'pages/ChangeHistory'

import Settings from 'pages/Settings'
import DashboardClients from 'pages/Dashboard/Clients'
import DashboardClientsChart from 'pages/Dashboard/ClientsChart'
import DashboardBudgets from 'pages/Dashboard/Budgets'
import DashboardBudgetsChart from 'pages/Dashboard/BudgetsChart'
import Clients from 'pages/Clients'
import Budgets from 'pages/Budgets'

import { AddBudget, EditExistingBudget } from 'pages/ManageBudget'

import Impersonation from 'pages/Impersonation'

// layout
import AuthorizedPageLayout from 'pages/shared/layouts/authorized-layout'
import SimpleAuthorizedPageLayout from 'pages/shared/layouts/simple-authorized-layout'

import { loadUserData } from 'state-manager/actions/auth'
import { ENV } from 'constants/main'
import { fetchBillingInfo } from 'state-manager/reducers/billing-info'
import { showGlobalModal } from 'state-manager/actions/global-modal'
import Campaigns from '../../pages/Campaigns'

const ConnectThirdPartyServices = () => {
  const dataSources = useAppSelector((state) => state.dataSources)
  const allClients = useAppSelector((state) => state.allClients)
  const allBudgets = useAppSelector((state) => state.allBudgets)
  const teamMembers = useAppSelector((state) => state.teamMembers)
  const subscription = useAppSelector((state) => state.subscriptions)
  const userData = useAppSelector((state) => state.userData)

  const {
    company,
    id: userID,
    email,
    firstName: firstname,
    lastName: lastname,
  } = userData

  const signUpAt = userData.createdAt ?? null

  const username = userID?.toString()

  const role = useMemo(
    () => teamMembers.data.find(({ id }) => id === userID)?.role,
    [teamMembers, userID]
  )

  const isClients = Boolean(allClients.data.length)
  const isBudget = Boolean(allBudgets.content.length)
  const isGoogleConnected =
    dataSources.data.findIndex(({ type }) => type === 'google') !== -1
  const isMisrosoftConnected =
    dataSources.data.findIndex(({ type }) => type === 'microsoft') !== -1
  const isFacebookConnected =
    dataSources.data.findIndex(({ type }) => type === 'facebook') !== -1
  const isDataSourceConnected =
    isGoogleConnected || isMisrosoftConnected || isFacebookConnected

  const dataSourceConnected = getAnswerDescription(isDataSourceConnected)
  const googleAdsConnected = getAnswerDescription(isGoogleConnected)
  const microsoftAdsConnected = getAnswerDescription(isMisrosoftConnected)
  const metaAdsConnected = getAnswerDescription(isFacebookConnected)
  const subscriptionStatus = getSubscriptionStatus(
    subscription.data.status === 'trialing' ||
      subscription.data.status === 'active',
    subscription.data.isOnTrial
  )
  const clientCreated = getAnswerDescription(isClients)
  const budgetCreated = getAnswerDescription(isBudget)
  const currentUsage = removeSpaces(subscription.data.currentMonthlyMediaSpend)
  const maxMediaSpend = removeSpaces(subscription.data.maximumMonthlyMediaSpend)

  const customProps = {
    dataSourceConnected,
    clientCreated,
    budgetCreated,
    googleAdsConnected,
    microsoftAdsConnected,
    metaAdsConnected,
    subscriptions: subscriptionStatus,
    currentUsage,
    maxMediaSpend,
  }

  useEffect(() => {
    if (username) {
      window.heap.identify(username)

      window.heap.addUserProperties({
        username: email,
        firstname,
        lastname,
        companyname: company.name,
        signUpAt,
        role,
        currentUsage,
        maxMediaSpend,
        dataSourceConnected,
        googleAdsConnected,
        microsoftAdsConnected,
        metaAdsConnected,
        subscription: subscriptionStatus,
        clientCreated,
        budgetCreated,
      })
    }
    //eslint-disable-next-line
  }, [])

  return (
    <ProductFruits
      projectCode={process.env.REACT_APP_PF_KEY}
      language="en"
      username={username}
      email={email}
      firstname={firstname}
      lastname={lastname}
      role={role}
      signUpAt={signUpAt}
      props={customProps}
    />
  )
}

const getRoutesGroupedByPermissions = (
  canManageSubscriptions: boolean,
  canInviteUsers: boolean,
  canManageDataSources: boolean,
  canSetUpNotifications: boolean,
  canSeeChangesHistory: boolean,
  canChangeSubscriptionPlan: boolean
) => [
  {
    permission: 'general',
    routes: [
      <Route
        exact
        key={routes.settings}
        path={routes.settings}
        component={Settings}
      />,
      <Route
        exact
        key={routes.dashboardClients}
        path={routes.dashboardClients}
        component={DashboardClients}
      />,
      <Route
        exact
        key={routes.dashboardClientsChart}
        path={routes.dashboardClientsChart}
        component={DashboardClientsChart}
      />,
      <Route
        exact
        key={routes.dashboardBudgets}
        path={routes.dashboardBudgets}
        component={DashboardBudgets}
      />,
      <Route
        exact
        key={routes.dashboardBudgetsChart}
        path={routes.dashboardBudgetsChart}
        component={DashboardBudgetsChart}
      />,
      <Route
        exact
        key={routes.dashboardCampaigns}
        path={routes.dashboardCampaigns}
        component={Campaigns}
      />,
      <Route
        exact
        key={routes.clients}
        path={routes.clients}
        component={Clients}
      />,
      <Route
        exact
        key={routes.budgets}
        path={routes.budgets}
        component={Budgets}
      />,
    ],
  },
  {
    permission: canManageSubscriptions,
    routes: [
      <Route
        exact
        key={routes.billing}
        path={routes.billing}
        component={Billing}
      />,
      <Route
        exact
        key={routes.invoices}
        path={routes.invoices}
        component={Invoices}
      />,
    ],
  },
  {
    permission: canChangeSubscriptionPlan,
    routes: [
      <Route exact key={routes.subscriptions} path={routes.subscriptions}>
        <Subscriptions hasUserSubscription={true} />
      </Route>,
    ],
  },
  {
    permission: canInviteUsers,
    routes: [
      <Route
        exact
        key={routes.teamMembers}
        path={routes.teamMembers}
        component={TeamMembers}
      />,
    ],
  },
  {
    permission: canManageDataSources,
    routes: [
      <Route
        exact
        key={routes.dataSources}
        path={routes.dataSources}
        component={DataSources}
      />,
      <Route
        exact
        key={routes.addDataSource}
        path={routes.addDataSource}
        component={AddDataSources}
      />,
      <Route
        exact
        key={routes.addDataSourceSuccess}
        path={routes.addDataSourceSuccess}
        component={AddDataSourceSuccess}
      />,
    ],
  },
  {
    permission: canSetUpNotifications,
    routes: [
      <Route
        exact
        key={routes.notifications}
        path={routes.notifications}
        component={Notifications}
      />,
    ],
  },
  {
    permission: canSeeChangesHistory,
    routes: [
      <Route
        exact
        key={routes.changesHistory}
        path={routes.changesHistory}
        component={ChangesHistory}
      />,
    ],
  },
]

const isLocalEnvironment = ENV === 'local'

const getAnswerDescription = (isTrue: boolean) => (isTrue ? 'yes' : 'no')

// TODO: check this
const getSubscriptionStatus = (
  hasCompanySubscription: boolean,
  isOnTrial?: boolean
) => {
  if (hasCompanySubscription) {
    if (isOnTrial) {
      return 'trialing'
    }
    return 'active'
  }
  return 'inactive'
}

const removeSpaces = (str: any) =>
  typeof str === 'string' ? str.replaceAll(' ', '') : ''

export const checkIfUserHasntDefaultPaymentMethod = (
  hasDefaultPaymentMethod: boolean,
  hasPaymentMethods: boolean,
  canManageSubscriptions: boolean,
  isSubscriptionActive: boolean
) =>
  Boolean(
    !hasDefaultPaymentMethod &&
      hasPaymentMethods &&
      canManageSubscriptions &&
      isSubscriptionActive
  )

const AuthorizedAccess = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const dataSources = useAppSelector((state) => state.dataSources)
  const allClients = useAppSelector((state) => state.allClients)
  const allBudgets = useAppSelector((state) => state.allBudgets)
  const teamMembers = useAppSelector((state) => state.teamMembers)
  const productFruits = useAppSelector((state) => state.productFruits)
  const subscription = useAppSelector((state) => state.subscriptions)
  const userData = useAppSelector((state) => state.userData)
  const billingInfo = useAppSelector((state) => state.billingInfo)

  const {
    isAuthorized: udIsAuthorized,
    hasVerifiedEmail: udHasVerifiedEmail,
    company: udCompany,
    permissions: udPermissions,
    id: userID,
  } = userData

  const hasSubscriptionsPlan = Boolean(subscription.data.plan?.id)

  const isImpersonation = location.pathname.includes(
    routes.impersonate.replace(/:.*/, '')
  )

  useEffect(() => {
    if (udIsAuthorized) {
      dispatch(fetchBillingInfo())
    }
    // eslint-disable-next-line
  }, [udIsAuthorized])

  useEffect(() => {
    if (udIsAuthorized && !productFruits.isDataLoaded && !isImpersonation) {
      dispatch(loadUserData())
    }
    // eslint-disable-next-line
  }, [udIsAuthorized, productFruits.isDataLoaded, isImpersonation])

  const isDataLoaded =
    dataSources.isLoaded &&
    allClients.isLoaded &&
    allBudgets.isLoaded &&
    teamMembers.isLoaded &&
    subscription.isLoaded &&
    Boolean(userID)

  useEffect(() => {
    if (isDataLoaded) {
      const { status } = subscription.data

      if (status === 'incomplete_expired') {
        dispatch(
          showGlobalModal({
            header: 'Warning',
            description:
              'Your subscription has ended. Confirm a plan to continue using the platform.',
          })
        )
      } else if (status === 'canceled') {
        dispatch(
          showGlobalModal({
            header: 'Warning',
            description:
              'Your subscription has ended. Confirm a plan to continue using the platform.',
          })
        )
      } else if (status === 'past_due') {
        dispatch(
          showGlobalModal({
            header: 'Warning',
            description:
              'Your subscription has ended. Payment method failed, add new valid default payment method or check the current one and proceed with the plan confirmation to continue using the platform.',
          })
        )
      }
    }
    //eslint-disable-next-line
  }, [isDataLoaded])

  // TODO Figure out how to prevent showing confirmEmail route to users while verified email prop is not set
  if (udIsAuthorized && udHasVerifiedEmail === null) {
    return null
  }

  return (
    <Route
      render={() => {
        if (isImpersonation) {
          return (
            <Switch>
              <Route
                exact
                path={routes.panel + routes.impersonate}
                component={Impersonation}
              />
            </Switch>
          )
        }

        if (!udIsAuthorized) {
          return (
            <Redirect
              to={{
                pathname: routes.modalSignIn[0],
                state: { from: location.pathname },
              }}
            />
          )
        }

        if (!isDataLoaded) {
          return null
        }

        if (udHasVerifiedEmail === 0) {
          return (
            <SimpleAuthorizedPageLayout>
              <Switch>
                <Route
                  exact
                  path={routes.confirmEmail}
                  component={ConfirmEmail}
                />

                <Redirect to={{ pathname: routes.confirmEmail }} />
              </Switch>
            </SimpleAuthorizedPageLayout>
          )
        }

        if (
          !udCompany.dataSources.length &&
          !udPermissions.canManageDataSources
        ) {
          return (
            <SimpleAuthorizedPageLayout>
              <div className="fs-lg">
                Sorry but you can&lsquo;t access this account because there is
                no data source connected to it
              </div>
            </SimpleAuthorizedPageLayout>
          )
        }

        if (!hasSubscriptionsPlan && udPermissions.canManageSubscriptions) {
          return (
            <Switch>
              <Route exact path={routes.subscriptions}>
                <Subscriptions hasUserSubscription={false} />
              </Route>
              <Route exact path={routes.billing} component={Billing} />

              <Redirect to={{ pathname: routes.subscriptions }} />
            </Switch>
          )
        }

        if (
          checkIfUserHasntDefaultPaymentMethod(
            Boolean(billingInfo.data?.defaultPaymentMethod),
            Boolean(billingInfo.data?.paymentMethods.length),
            udPermissions.canManageSubscriptions,
            subscription.data.status === 'active'
          )
        ) {
          return (
            <Switch>
              <AuthorizedPageLayout>
                <Switch>
                  <Route
                    exact
                    key={routes.billing}
                    path={routes.billing}
                    component={Billing}
                  />
                  <Route
                    exact
                    key={routes.subscriptions}
                    path={routes.subscriptions}
                  >
                    <Subscriptions hasUserSubscription={true} />
                  </Route>
                  <Route exact key={routes.invoices} path={routes.invoices}>
                    <Invoices />
                  </Route>

                  <Redirect to={{ pathname: routes.invoices }} />
                </Switch>
              </AuthorizedPageLayout>
            </Switch>
          )
        }
        const { isOnTrial, status } = subscription.data

        const hasUserAccessToThePlatform =
          isOnTrial || subscription.data.isSubscriptionActive

        if (hasUserAccessToThePlatform) {
          const routesGroupsByPermission = getRoutesGroupedByPermissions(
            udPermissions.canManageSubscriptions,
            udPermissions.canInviteUsers,
            udPermissions.canManageDataSources,
            udPermissions.canSetUpNotifications,
            udPermissions.canSeeChangesHistory,
            status !== 'incomplete' && status !== 'past_due'
          )

          const renderRoutesGroupsByPermission = () =>
            routesGroupsByPermission.map((routesGroup) =>
              routesGroup.permission
                ? routesGroup.routes.map((route) => route)
                : null
            )

          return (
            <>
              {!isLocalEnvironment && <ConnectThirdPartyServices />}
              <Switch>
                {(udPermissions.canManageClients ||
                  udPermissions.canManageBudgets) && [
                  <Route
                    exact
                    key={routes.addBudget}
                    path={routes.addBudget}
                    component={AddBudget}
                  />,
                  <Route
                    exact
                    key={routes.editBudget}
                    path={routes.editBudget}
                    component={EditExistingBudget}
                  />,
                ]}

                <AuthorizedPageLayout>
                  <Switch>
                    {renderRoutesGroupsByPermission()}

                    <Redirect to={{ pathname: routes.dashboardClients }} />
                  </Switch>
                </AuthorizedPageLayout>
              </Switch>
            </>
          )
        } else {
          if (udPermissions.canManageSubscriptions) {
            return (
              <AuthorizedPageLayout>
                <Switch>
                  <Route
                    exact
                    key={routes.billing}
                    path={routes.billing}
                    component={Billing}
                  />
                  {status !== 'past_due' && (
                    <Route
                      exact
                      key={routes.subscriptions}
                      path={routes.subscriptions}
                    >
                      <Subscriptions hasUserSubscription={true} />
                    </Route>
                  )}
                  <Route exact key={routes.invoices} path={routes.invoices}>
                    <Invoices />
                  </Route>

                  <Route path="*">
                    <div className="mt-5 text-center">
                      <p>Renew your subscription to have access to this page</p>
                      <Link to={routes.invoices} data-cy="go-to-invoices">
                        Go to Billing page
                      </Link>
                    </div>
                  </Route>
                </Switch>
              </AuthorizedPageLayout>
            )
          } else {
            return (
              <SimpleAuthorizedPageLayout>
                <div className="fs-lg">
                  Sorry but you can&lsquo;t access this account because the
                  subscription has ended on it
                </div>
              </SimpleAuthorizedPageLayout>
            )
          }
        }
      }}
    />
  )
}

export default AuthorizedAccess
