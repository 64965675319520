// actions
import { getSingleBudget } from 'state-manager/actions/budgets'

// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'
import parseFilters from 'utils/parse/parseFilters'

// constants
import { CAMPAIGN_ACTIONS } from 'state-manager/constants'
import { filter } from 'components/ui/Table'

// types
import { AppDispatch } from 'state-manager/store'
import { Dispatch } from 'redux'
import { IFilter } from '../reducers/all-campaigns'
import { SortingRule } from 'react-table'

const urlPrefix = 'campaign'

export const getAllCampaigns =
  (
    page?: number,
    itemsPerPage?: number,
    filters: Array<IFilter> = [],
    sort: SortingRule | null = null
  ) =>
  (dispatch: Dispatch) => {
    const params = {
      count: itemsPerPage,
      page,
      ...parseFilters(filters),
    }

    if (sort) {
      const value = sort.desc ? 'desc' : 'asc'

      params.sort = {
        [sort.id]: value,
      }
    }
    dispatch({ type: CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.ATTEMPT })

    reqH({
      method: 'GET',
      urlPrefix,
      url: 'table',
      params,
    })
      .then((res) => {
        dispatch({
          type: CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.SUCCESS,
          data: res.data.result,
        })
      })
      .catch(() => {
        dispatch({ type: CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.ERROR })
      })
  }

export const getUrlForExportCampaignTable =
  (format: string) => (dispatch: AppDispatch) =>
    reqH<{ result: { url: string } }>({
      method: 'GET',
      urlPrefix,
      url: `export/${format}`,
    })
      .then((res) => {
        dispatch({
          type: CAMPAIGN_ACTIONS.CAMPAIGN_EXPORT_TABLE.SUCCESS,
          data: res.data.result,
        })
        return res.data.result
      })
      .catch(() => {
        dispatch({ type: CAMPAIGN_ACTIONS.CAMPAIGN_EXPORT_TABLE.ERROR })
      })

export const getAllCampaignsSimple = (
  dispatch: Dispatch,
  page: number,
  itemsPerPage: number,
  budgetId?: string,
  filters?: Array<filter>
) => {
  filters = filters?.map((item) =>
    item.id === 'name' ? { id: 'campaign', value: item.value } : item
  )
  const params = {
    budgetId,
    page,
    count: itemsPerPage,
    ...parseFilters(filters),
  }

  return reqH({
    method: 'GET',
    url: urlPrefix,
    params,
  })
    .then((res) => {
      dispatch({
        type: CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL_SIMPLE.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      notification.error('Unable to load campaigns')
    })
}

export const updateCampaignStatus = (id, status) => (dispatch: Dispatch) =>
  reqH({
    method: 'PUT',
    urlPrefix,
    url: `${id}/status`,
    data: status,
  })
    .then(() => {
      notification.success('Campaign status successfully edited!')
      dispatch(getAllCampaigns())
    })
    .finally(() => {
      dispatch({ type: CAMPAIGN_ACTIONS.CAMPAIGN_UPDATE_STATUS.SYSTEM })
    })

export const updateCampaignDailyBudget =
  (budgetId: string, id: string, dailyBudget: number) => (dispatch: Dispatch) =>
    reqH({
      method: 'PUT',
      urlPrefix,
      url: `${id}/daily-budget`,
      data: {
        dailyBudget,
      },
    })
      .then(() => {
        notification.success('Campaign daily budget successfully edited!')
        dispatch(getSingleBudget(budgetId))
      })
      .finally(() => {
        dispatch({ type: CAMPAIGN_ACTIONS.CAMPAIGN_UPDATE_DAILY_BUDGET.SYSTEM })
      })
